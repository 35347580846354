<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="cont_foto_destaque" :src="`${base_url}upload/content_photo/g/${cont_foto_destaque}?${uuid}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Conteúdos</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="loadContents">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'contents.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Novo Conteúdo
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="content" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.cont_foto_destaque`]="{ item }">
                <img v-if="item.cont_foto_destaque" :src="`${base_url}upload/content_photo/p/${item.cont_foto_destaque}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.cont_foto_destaque)">
                <img v-if="!item.cont_foto_destaque" :src="`${base_url}upload/logo.png`" class="avatar">
            </template>
            <template v-slot:[`item.lang_flag`]="{ item }">
                <img v-if="item.language.lang_flag" :src="`${base_url}upload/language/${item.language.lang_flag}`" class="avatar">
            </template>
            <template v-slot:[`item.cont_publicado`]="{ item }">
                    <v-switch color="lime" v-model="item.cont_publicado" @change="onChangeEventHandler(item.cont_id, $event)" class="my-switch"></v-switch>
            </template>
            <template v-slot:[`item.cont_destaque_home`]="{ item }">
                    <v-switch color="lime" v-model="item.cont_destaque_home" @change="onChangeEventHandler(item.cont_id, $event)" class="my-switch"></v-switch>
            </template>
            <template v-slot:[`item.cont_status`]="{ item }">
                    <v-switch color="lime" v-model="item.cont_status" @change="onChangeEventHandler(item.cont_id, $event)" class="my-switch"></v-switch>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editContent(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteContent(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailContent(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>

                <v-tooltip top color="teal darken-1 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="teal darken-1 lighten-1" @click.prevent="fotoContent(item)" v-on="on">
                            <v-icon dark class="mdi mdi-image-plus"></v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar Fotos</span>
                </v-tooltip>

                <v-tooltip top color="orange darken-1 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="orange darken-1 lighten-1" @click.prevent="listFotoContent(item)" v-on="on">
                            <v-icon dark class="mdi mdi-image"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ver Fotos</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from "../../../config/configs";

export default {
    created() {
        this.loadContents();
    },
    computed: {},
    name: "ContentsComponent",
    data() {
        return {
            cont_foto_destaque: null,
            dialog_foto: false,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    text: "Imagem",
                    align: "left",
                    sortable: false,
                    value: "cont_foto_destaque"
                },
                {
                    text: "Idioma",
                    align: "left",
                    sortable: false,
                    value: "lang_flag"
                },
                {
                    sortable: false,
                    text: "Titulo",
                    value: "cont_titulo"
                },
                {
                    sortable: false,
                    text: "Publicado",
                    value: "cont_publicado"
                },
                {
                    sortable: false,
                    text: "Destaque Home",
                    value: "cont_destaque_home"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "cont_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            content: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        ampliarFoto(foto) {
            this.cont_foto_destaque = foto;
            this.dialog_foto = true;
        },
        loadContents() {
            this.$store
                .dispatch("loadContents")
                .then(response => {
                    this.content = response.data;
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Erro",
                        text: "Dados não localizado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        editContent(item) {
            this.$router.push({
                name: "contents.edit",
                params: {
                    cont_id: item.cont_id
                }
            });
        },
        detailContent(item) {
            this.$router.push({
                name: "contents.detail",
                params: {
                    cont_id: item.cont_id
                }
            });
        },
        fotoContent(item) {
            this.$router.push({
                name: "contents.foto",
                params: {
                    cont_id: item.cont_id
                }
            });
        },
        listFotoContent(item) {
            this.$router.push({
                name: "contentphotos",
                params: {
                    content_id: item.cont_id
                }
            });
        },
        deleteContent(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyContent", item.cont_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.loadContents();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlerStatus(cont_id, value) {
            this.$store
                .dispatch("updateContentStatus", {
                    cont_id: cont_id,
                    cont_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Popup atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Popup",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerPublicado(cont_id, value) {
            this.$store
                .dispatch("updateContentPublicado", {
                    cont_id: cont_id,
                    cont_publicado: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerDestaqueHome(cont_id, value) {
            this.$store
                .dispatch("updateContentDestaqueHome", {
                    cont_id: cont_id,
                    cont_destaque_home: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
